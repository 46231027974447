let webData = {
    "role": "Laboratoire de Système et de Sécurité d'Étudiants (LSE)",
    "name": "LSE",
    "addr": "Paris",
    "email": "contact@lse.re",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/company/96961536/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"git",
          "url":"https://git.lse.re/explore/repos",
          "className":"fa fa-github"
        },
        {
          "name": "LSECTF",
          "url": "https://ctf.lse.re/",
          "className": "fa fa-flag"
        },
        {
          "name": "Discord",
          "url": "https://discord.gg/GKAUFmA3bW",
          "className": "fa fa-users"
        },
        {
          "name": "HelloAsso",
          "url": "https://www.helloasso.com/associations/lse-laboratoire-de-systeme-et-de-securite-d-etudiants",
          "className": "fa fa-edit"
        }
      ],
    "mission":[
      {
        "specialization" : "Le LSE est une association étudiante française. Fondée par d'anciens étudiants du Laboratoire éponyme, elle a pour but de pousser des étudiants à s'investir et à s'impliquer dans des projets de développement en système et en sécurité. Elle maintient également un ensemble de services, dont le LSECTF. Nous sommes également ouverts à la réalisation de missions diverses pour des entreprises. Nous avons également une équipe de CTF, l'équipe LSE."
      },
    ],
    "portfolio":[
      {
        "name":"Cours",
        "description":"Cours de Rétro-ingénierie, de Kernel et d'assembleur",
        "content":"Ces cours sont donnés par Daniel \"DaemonOnUnix\" Frédéric, président de l'association, à EPITA.",
        "imgurl":"images/no-bg.png",
        "url": ""
      },
      {
        "name":"Conférences",
        "description":"Conférences sur nos thématiques",
        "content":"Conférences régulières en Sécu, Sys, Hardware, Infra, CTF. Suivies de barbecues chaleureux, avec éventuellement des challenges de CTF sur le LSECTF !",
        "imgurl": "images/no-bg.png",
        "url": "https://www.helloasso.com/associations/lse-laboratoire-de-systeme-et-de-securite-d-etudiants"
      },
      {
        "name":"Projets",
        "description":"Projets de développement",
        "content":"Projets de développement en cours ! Venez regarder les publics sur notre Forgejo !",
        "imgurl": "images/no-bg.png",
        "url": "https://git.lse.re/"
      },
      {
        "name":"CTF",
        "description":"Équipe de CTF",
        "content":"Équipe de CTF, l'équipe LSE. Venez nous rejoindre, et entrainez-vous sur notre CTF, ou aidez à le développer !",
        "imgurl": "https://ctftime.org/static/images/ct/logo.svg",
        "url": "https://ctftime.org/team/757"
      },

    ],
    "Projects":[
      {
        "name":"KassOS",
        "description":"Microkernel project",
        "content":"Projet de microkernel pouvant agir comme RTOS, bootstrappant un système avec utilisation optionnelle de MMU",
        "imgurl":"images/no-bg.png",
        "url": "www.google.com"
      },
    ],
    "testimonials":[
    ]
  }
  
  export default webData